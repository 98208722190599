export const COLLECTION_APP = 'apps';
export const COLLECTION_APP_BUILD = 'app/builds';
export const COLLECTION_APP_VERSION = 'app/versions';
export const COLLECTION_APP_BINARY = 'app/binaries';
export const COLLECTION_APP_SCREENSHOT = 'app/screenshots';
export const COLLECTION_APP_RESOURCE = 'app/resources';
export const COLLECTION_APP_PROVIDER = 'app/providers';
export const COLLECTION_APP_PLUGIN = 'app/plugins';
export const COLLECTION_APP_LINK = 'app/links';
export const COLLECTION_TAG = 'tags';
export const COLLECTION_CATEGORY = 'categories';
export const COLLECTION_COLLECTION = 'collections';
export const COLLECTION_USER = 'users';
export const COLLECTION_LINK = 'links';

export const TOKEN_KEY = '__JWT_TOKEN__';
export const AUTH_KEY = 'userInfo';

export enum BinaryStatus {
  Pending = 'pending',
  Processing = 'processing',
  Succeed = 'succeed',
  Failed = 'failed',
  Published = 'published',
  Skipped = 'skipped',
}

export const BinaryStatusOptions = Object.entries(BinaryStatus).map(
  ([_key, value]) => ({
    id: value,
    name: value,
  })
);

export enum ScreenshotStatus {
  None = 'none',
  Pending = 'pending',
  Approved = 'approved',
  Rejected = 'rejected',
}

export const ScreenshotStatusOptions = Object.entries(ScreenshotStatus).map(
  ([_key, value]) => ({
    id: value,
    name: value,
  })
);

export enum DeviceDisplay {
  Iphone55 = 'iphone_55',
  Iphone65 = 'iphone_65',
  IpadPro = 'ipad_pro',
  Custom = 'custom',
}

export const DeviceDisplayOptions = Object.entries(DeviceDisplay).map(
  ([_key, value]) => ({
    id: value,
    name: value,
  })
);

export enum ResourceStatus {
  Pending = 'pending',
  Publishable = 'publishable',
  Preparing = 'preparing',
  Done = 'done',
  Skipped = 'skipped',
}
export const ResourceStatusOptions = Object.entries(ResourceStatus).map(
  ([_key, value]) => ({
    id: value,
    name: value,
  })
);

export enum AppOrientation {
  Portrait = 'portrait',
  Landscape = 'landscape',
  Both = 'both',
}
export const AppOrientationOptions = Object.entries(AppOrientation).map(
  ([_key, value]) => ({
    id: value,
    name: value,
  })
);

export enum ResourceStoreStatus {
  Pending = 'pending',
  Ready = 'ready',
  Releasing = 'releasing',
  Released = 'released',
  Updating = 'updating',
  Updated = 'updated',
  Reviewing = 'reviewing',
  Appealing = 'appealing',
  Rejected = 'rejected',
  Dead = 'dead',
}
export const ResourceStoreStatusOptions = Object.entries(
  ResourceStoreStatus
).map(([_key, value]) => ({
  id: value,
  name: value,
}));

export enum AgentName {
  VideoAgent = 'video_agent',
  FirebaseAgent = 'firebase_agent',
  AdAgent = 'ad_agent',
  AppStoreAgent = 'app_store_agent',
  GooglePlayAgent = 'google_play_agent',
  AmazonStoreAgent = 'amazon_store_agent',
  SamsungGalaxyStoreAgent = 'samsung_galaxy_store_agent',
  HuaweiAppGalleryAgent = 'huawei_app_gallery_agent',
  XiaomiGetAppsAgent = 'xiaomi_get_apps_agent',
  OppoAppMarketAgent = 'oppo_app_market_agent',
  ConvertPortalAgent = 'convert_portal_agent',
}

export const AgentNameOptions = Object.entries(AgentName).map(
  ([_key, value]) => ({
    id: value,
    name: value,
  })
);

export enum AgentQueueName {
  Agent1 = 'agent1',
  Agent2 = 'agent2',
  Agent3 = 'agent3',
  Agent4 = 'agent4',
  Agent5 = 'agent5',
  Agent6 = 'agent6',
  Agent7 = 'agent7',
  Agent8 = 'agent8',
  Agent9 = 'agent9',
  Agent10 = 'agent10',
  Agent11 = 'agent11',
  Agent12 = 'agent12',
  Agent13 = 'agent13',
  Agent14 = 'agent14',
  Agent15 = 'agent15',
  Agent16 = 'agent16',
  Agent17 = 'agent17',
  Agent18 = 'agent18',
  Agent19 = 'agent19',
  Agent20 = 'agent20',
  Agent21 = 'agent21',
  Agent22 = 'agent22',
  Agent23 = 'agent23',
  Agent24 = 'agent24',
}

export const AgentQueueNameOptions = Object.entries(AgentQueueName).map(
  ([_key, value]) => ({
    id: value,
    name: value,
  })
);

export enum JobName {
  AddApp = 'add_app',
  ReleaseApp = 'release_app',
  UpdateApp = 'update_app',
  ReportApp = 'report_app',
}

export const JobNameOptions = Object.entries(JobName).map(([_key, value]) => ({
  id: value,
  name: value,
}));

export enum LinkType {
  None = 'none',
  Rewrite = 'rewrite',
  Redirect = 'redirect',
  Whitelist = 'whitelist',
  Blacklist = 'blacklist',
}

export const LinkTypeOptions = Object.entries(LinkType).map(
  ([_key, value]) => ({
    id: value,
    name: value,
  })
);

export enum AppLinkStatus {
  Pending = 'pending',
  Approved = 'approved',
  Rejected = 'rejected',
}

export const AppLinkStatusOptions = Object.entries(AppLinkStatus).map(
  ([_key, value]) => ({
    id: value,
    name: value,
  })
);

export enum AppProviderSlug {
  PokiCom = 'poki.com',
  GameDistributionCom = 'gamedistribution.com',
  GameMonetizeCom = 'gamemonetize.com',
  FrivCom = 'friv.com',
  CrazyGamesCom = 'crazygames.com',
  Y8Com = 'y8.com',
  W2mGamesCom = 'w2mgames.com',
}

export const AppProviderSlugOptions = Object.entries(AppProviderSlug).map(
  ([_key, value]) => ({
    id: value,
    name: value,
  })
);
