import * as React from 'react';
import { FC } from 'react';
import {
  DateField,
  FormTab,
  TabbedForm,
  TextField,
  Show,
  ShowProps,
  NumberField,
  ImageField,
  RichTextField,
  BooleanField,
  ReferenceManyField,
  EditButton,
  Pagination,
  Datagrid,
  ShowActionsProps,
  TopToolbar,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { JsonField } from 'react-admin-json-view';
import { ImageListField, LinkField, VideoField } from '../components/fields';
import {
  COLLECTION_APP,
  COLLECTION_APP_VERSION,
  COLLECTION_APP_BUILD,
  COLLECTION_APP_BINARY,
  COLLECTION_APP_SCREENSHOT,
} from '../utils';
import { CustomToolbar } from '../components/toolbars';
import { CreateButtonWithParams } from '../components/buttons';
import JsonFieldUtils from '../components/utils/JsonFieldUtils';
import { ActionResourceButtons } from './AppResourceButtons';

const useStyles = makeStyles({
  comment: {
    maxWidth: '20em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  tab: {
    display: 'block',
  },
  image: {
    width: 128,
    height: 128,
  },
});

const AppResourceShowActions: React.FC<ShowActionsProps> = props => {
  return (
    <TopToolbar>
      <ActionResourceButtons {...props} />
      <EditButton basePath={props.basePath} record={props.data} />
    </TopToolbar>
  );
};

const AppResourceShow: FC<ShowProps> = props => {
  const { permissions } = props;
  const canModerate = permissions?.admin || permissions?.moderator;
  const classes = useStyles();
  return (
    <Show {...props} actions={<AppResourceShowActions />}>
      <TabbedForm redirect="show" toolbar={<CustomToolbar />}>
        <FormTab
          label="resources.apps.tabs.details"
          contentClassName={classes.tab}
        >
          <ReferenceManyField
            reference={COLLECTION_APP_SCREENSHOT}
            target="appId"
            label="resources.app_screenshot.name"
            perPage={5}
            pagination={<Pagination />}
            fullWidth
            sort={{ field: 'updatedAt', order: 'DESC' }}
          >
            <Datagrid rowClick="show">
              <LinkField
                label="id"
                source="id"
                reference={COLLECTION_APP_SCREENSHOT}
              />
              {canModerate && (
                <LinkField
                  source="appId"
                  reference={COLLECTION_APP}
                  label="App"
                />
              )}
              <TextField source="deviceDisplay" />
              <TextField source="status" />
              <DateField source="updatedAt" />
              <DateField source="updatedAt" />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>

          <CreateButtonWithParams
            reference={COLLECTION_APP_VERSION}
            params={{ id: 'appId' }}
            label="Create App version"
          />
          <ReferenceManyField
            reference={COLLECTION_APP_VERSION}
            target="appId"
            label="resources.app_version.name"
            perPage={5}
            pagination={<Pagination />}
            fullWidth
            sort={{ field: 'updatedAt', order: 'DESC' }}
          >
            <Datagrid rowClick="show">
              <LinkField
                label="id"
                source="id"
                reference={COLLECTION_APP_VERSION}
              />
              {canModerate && (
                <LinkField
                  source="appId"
                  reference={COLLECTION_APP}
                  label="App"
                />
              )}
              <TextField source="name" />
              <DateField source="updatedAt" />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>

          <ReferenceManyField
            reference={COLLECTION_APP_BUILD}
            target="appId"
            label="resources.app_build.name"
            perPage={5}
            pagination={<Pagination />}
            fullWidth
            sort={{ field: 'updatedAt', order: 'DESC' }}
          >
            <Datagrid rowClick="show">
              <LinkField
                label="id"
                source="id"
                reference={COLLECTION_APP_BUILD}
              />
              {canModerate && (
                <LinkField
                  source="appId"
                  reference={COLLECTION_APP}
                  label="App"
                />
              )}
              <LinkField
                source="versionId"
                reference={COLLECTION_APP_VERSION}
                label="Version"
              />
              <NumberField source="number" />
              <DateField source="updatedAt" />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>
          <ReferenceManyField
            reference={COLLECTION_APP_BINARY}
            target="appId"
            label="resources.app_binary.name"
            perPage={5}
            pagination={<Pagination />}
            fullWidth
            sort={{ field: 'updatedAt', order: 'DESC' }}
          >
            <Datagrid rowClick="show">
              <LinkField
                label="id"
                source="id"
                reference={COLLECTION_APP_BINARY}
              />
              {canModerate && (
                <LinkField
                  label="App"
                  source="appId"
                  reference={COLLECTION_APP}
                />
              )}
              <LinkField
                label="Version"
                source="versionId"
                reference={COLLECTION_APP_VERSION}
              />
              <LinkField
                label="Build"
                source="buildId"
                reference={COLLECTION_APP_BUILD}
              />
              <TextField source="status" />
              <DateField source="updatedAt" />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>

          <TextField label="Owner" source="metadata.owner" />
          {canModerate && (
            <LinkField label="App" source="id" reference={COLLECTION_APP} />
          )}
          <LinkField
            label="Latest Version"
            source="metadata.latestVersionId"
            reference={COLLECTION_APP_VERSION}
          />
          <LinkField
            label="Latest Build"
            source="metadata.latestBuildId"
            reference={COLLECTION_APP_BUILD}
          />
          <LinkField
            label="Latest Binary"
            source="metadata.latestBinaryId"
            reference={COLLECTION_APP_BINARY}
          />
          <NumberField source="accountId" />
          <TextField source="queueName" />
          <NumberField source="amazonAccountId" />
          <TextField source="amazonQueueName" />
          <NumberField source="samsungAccountId" />
          <TextField source="samsungQueueName" />
          <TextField source="status" />
          <NumberField source="priority" />
          <NumberField source="downloads" />
          <TextField source="name" />
          <TextField source="title" />
          <RichTextField source="shortDescription" />
          <RichTextField source="description" />
          <ImageField className={classes.image} source="image" />
          <ImageListField
            label="Custom Images"
            source="screenshots.customImages"
          />
          <BooleanField
            label="Custom Images Ready"
            source="screenshots.customReady"
            emptyText={'No'}
          />
          <VideoField
            label="Walkthrough Video"
            source="videos.walkThroughYoutubeId"
          />
          {canModerate && (
            <JsonField {...JsonFieldUtils.generateProps('videos')} />
          )}
          {canModerate && (
            <JsonField {...JsonFieldUtils.generateProps('metadata')} />
          )}
          {canModerate && <JsonField {...JsonFieldUtils.generateProps('ad')} />}
          {canModerate && (
            <JsonField {...JsonFieldUtils.generateProps('appStore')} />
          )}
          {canModerate && (
            <JsonField {...JsonFieldUtils.generateProps('googlePlay')} />
          )}
          {canModerate && (
            <JsonField {...JsonFieldUtils.generateProps('amazonStore')} />
          )}
          {canModerate && (
            <JsonField
              {...JsonFieldUtils.generateProps('samsungGalaxyStore')}
            />
          )}
          {canModerate && (
            <JsonField {...JsonFieldUtils.generateProps('huaweiAppGallery')} />
          )}
          {canModerate && (
            <JsonField {...JsonFieldUtils.generateProps('xiaomiGetApps')} />
          )}
          {canModerate && (
            <JsonField {...JsonFieldUtils.generateProps('oppoAppMarket')} />
          )}
          <DateField source="updatedAt" />
        </FormTab>
      </TabbedForm>
    </Show>
  );
};

export default AppResourceShow;
