import * as React from 'react';
import { FC } from 'react';
import {
  List,
  ListProps,
  Datagrid,
  TextField,
  DateField,
  Filter,
  EditButton,
  TextInput,
  SelectInput,
} from 'react-admin';
import { LinkField } from '../components/fields';
import {
  COLLECTION_APP_BINARY,
  COLLECTION_APP_BUILD,
  COLLECTION_APP_VERSION,
  BinaryStatusOptions,
  COLLECTION_APP_RESOURCE,
} from '../utils';

const BinaryFilter = (props: any) => {
  return (
    <Filter {...props}>
      <TextInput label="Id" source="id_eq" defaultValue="" alwaysOn />
      <TextInput label="Resource" source="appId_eq" defaultValue="" alwaysOn />
      <SelectInput
        label="Status"
        source="status_eq"
        choices={BinaryStatusOptions}
        alwaysOn
      />
      <SelectInput
        label="Ipa status"
        source="ipaStatus_eq"
        choices={BinaryStatusOptions}
        alwaysOn
      />
      <SelectInput
        label="Amazon status"
        source="amazonStatus_eq"
        choices={BinaryStatusOptions}
        alwaysOn
      />
      <SelectInput
        label="Samsung status"
        source="samsungStatus_eq"
        choices={BinaryStatusOptions}
        alwaysOn
      />
      <SelectInput
        label="Huawei status"
        source="huaweiStatus_eq"
        choices={BinaryStatusOptions}
        alwaysOn
      />
      <SelectInput
        label="Xiaomi status"
        source="xiaomiStatus_eq"
        choices={BinaryStatusOptions}
        alwaysOn
      />
      <SelectInput
        label="Oppo status"
        source="oppoStatus_eq"
        choices={BinaryStatusOptions}
        alwaysOn
      />
    </Filter>
  );
};

const AppBinaryList: FC<ListProps> = props => {
  return (
    <List
      {...props}
      perPage={25}
      sort={{ field: 'updatedAt', order: 'DESC' }}
      bulkActionButtons={false}
      filters={<BinaryFilter />}
    >
      <Datagrid optimized rowClick="show">
        <LinkField label="id" source="id" reference={COLLECTION_APP_BINARY} />
        <LinkField
          label="Resource"
          source="appId"
          reference={COLLECTION_APP_RESOURCE}
        />
        <LinkField
          label="Version"
          source="versionId"
          reference={COLLECTION_APP_VERSION}
        />
        <LinkField
          label="Build"
          source="buildId"
          reference={COLLECTION_APP_BUILD}
        />
        <TextField source="status" />
        <TextField source="ipaStatus" />
        <TextField source="amazonStatus" />
        <TextField source="samsungStatus" />
        <TextField source="huaweiStatus" />
        <TextField source="xiaomiStatus" />
        <TextField source="oppoStatus" />
        <DateField source="updatedAt" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default AppBinaryList;
